import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import BookIcon from '@mui/icons-material/Book';
import HistoryIcon from '@mui/icons-material/History';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import { Divider, TablePagination, Modal } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import './styles/Home.css';
import calendarImage from '../assets/images/calendar.png';
import checklistImage from '../assets/images/checklist.png';
import InjectionSchedule from './InjectionSchedule'; // InjectionSchedule 컴포넌트 임포트
import Embryo from './Embryo'; // Embryo 컴포넌트 임포트

const dictUsagePurpose = {
  gnrhAntagonist: "조기배란 억제",
  implantation: "착상 유도",
  hcg: "배란유도",
  fsh: "난포성숙"
};

const drawerWidth = 240;

const Home = () => {
  const [injectionData, setInjectionData] = useState([]);
  const [embryoData, setEmbryoData] = useState([]);
  const [injectionPage, setInjectionPage] = useState(0);
  const [embryoPage, setEmbryoPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false); // InjectionSchedule Modal 상태 관리
  const [openEmbryoModal, setOpenEmbryoModal] = useState(false); // Embryo Modal 상태 관리

  const fetchInjectionData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST_URL}/injections/hospital/user/5/`);
      setInjectionData(response.data);
    } catch (error) {
      console.error('Error fetching injection data:', error);
    }
  };

  const fetchEmbryoData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST_URL}/embryo/hospital/user/5/`);
      setEmbryoData(response.data);
    } catch (error) {
      console.error('Error fetching embryo data:', error);
    }
  };

  useEffect(() => {
    fetchInjectionData();
    fetchEmbryoData();
  }, []);

  const handleChangeInjectionPage = (event, newPage) => {
    setInjectionPage(newPage);
  };

  const handleChangeEmbryoPage = (event, newPage) => {
    setEmbryoPage(newPage);
  };

  const handleChangeInjectionRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setInjectionPage(0);
  };

  const handleChangeEmbryoRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setEmbryoPage(0);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEmbryoModal = () => setOpenEmbryoModal(true);
  const handleCloseEmbryoModal = () => setOpenEmbryoModal(false);

  const monthlyFreezedEmbryos = embryoData.reduce((acc, embryo) => {
    const month = new Date(embryo.retrievedDate).getMonth() + 1;
    if (!acc[month]) {
      acc[month] = 0;
    }
    acc[month] += embryo.freezedEmbryos;
    return acc;
  }, {});

  const monthlyData = Object.keys(monthlyFreezedEmbryos).map((month) => ({
    retrievedDate: month,
    freezedEmbryos: monthlyFreezedEmbryos[month],
  }));

  let cumulativeCount = 0;
  const cumulativeData = monthlyData.map((data) => {
    cumulativeCount += data.freezedEmbryos;
    return { ...data, cumulativeFreezedEmbryos: cumulativeCount };
  });

  const recentData = cumulativeData.slice(-5);

  return (
    <Box sx={{ display: 'flex' }} style={{ backgroundColor: '#f8f8f8' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ backgroundColor: '#8953ff' }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ marginRight: 5 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            서울 채병원
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="진료 일정" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="예약 관리" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="진료 이력 조회" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText primary="레포트" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="설정" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, mt: 12, ml: 4, mr: 4, mb: 2 }}
        style={{ backgroundColor: '#f8f8f8' }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Paper sx={{ flex: 1.5, padding: 3, backgroundColor: '#ffffff', mr: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4">
                개나리 (id:68)
              </Typography>
              <Typography
                variant="body2"
                component={Link}
                to="/change-patient"
                sx={{ color: '#8953ff', cursor: 'pointer', textDecoration: 'none' }}
              >
                환자 변경 &gt;
              </Typography>
            </Box>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{ mb: 5 }}
            >
              1987년 11월 30일생 / 시험관 2회차
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 1.5, fontWeight: 'bold' }}
            >
              주사제
            </Typography>
            <TableContainer sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>주사제 이름</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>투여 용량</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>단위</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>사용 목적</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>투여 시작일자</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>투여 종료일자</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {injectionData.slice(injectionPage * rowsPerPage, injectionPage * rowsPerPage + rowsPerPage).map((injection) => (
                    <TableRow key={injection.id}>
                      <TableCell>{injection.injectionName}</TableCell>
                      <TableCell>{injection.volume}</TableCell>
                      <TableCell>{injection.unit}</TableCell>
                      <TableCell>{dictUsagePurpose[injection.usagePurpose] || injection.usagePurpose}</TableCell>
                      <TableCell>{injection.injectionStartDate}</TableCell>
                      <TableCell>{injection.injectionEndDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={injectionData.length}
                rowsPerPage={rowsPerPage}
                page={injectionPage}
                onPageChange={handleChangeInjectionPage}
                onRowsPerPageChange={handleChangeInjectionRowsPerPage}
              />
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              sx={{ mb: 10, mt: 2 }}
              style={{ backgroundColor: '#8953ff' }}
              fullWidth
            >
              새로운 주사제 추가
            </Button>
            <Typography
              variant="h6"
              sx={{ mb: 1.5, fontWeight: 'bold' }}
            >
              난자/배아 정보
            </Typography>
            <TableContainer sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>난자 채취일자</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>채취 난자 수</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>성숙 난자</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>수정란</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>3일배아</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>4일배아</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>5일배아</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>동결배아 개수</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {embryoData.slice(embryoPage * rowsPerPage, embryoPage * rowsPerPage + rowsPerPage).map((embryo) => (
                    <TableRow key={embryo.id}>
                      <TableCell>{embryo.retrievedDate}</TableCell>
                      <TableCell>{embryo.retrievedOva}</TableCell>
                      <TableCell>{embryo.matureOva}</TableCell>
                      <TableCell>{embryo.zygotes}</TableCell>
                      <TableCell>{embryo.threeDaysEmbryos}</TableCell>
                      <TableCell>{embryo.fourDaysEmbryos}</TableCell>
                      <TableCell>{embryo.fiveDaysEmbryos}</TableCell>
                      <TableCell>{embryo.freezedEmbryos}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={embryoData.length}
                rowsPerPage={rowsPerPage}
                page={embryoPage}
                onPageChange={handleChangeEmbryoPage}
                onRowsPerPageChange={handleChangeEmbryoRowsPerPage}
              />
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenEmbryoModal}
              sx={{ mb: 2, mt: 2 }}
              style={{ backgroundColor: '#8953ff' }}
              fullWidth
            >
              난자/배아 정보 추가
            </Button>
          </Paper>
          <Paper sx={{ flex: 1, padding: 2, backgroundColor: '#ffffff' }}>
            <Box sx={{ mb: 4 }}>
              <img
                src={calendarImage}
                alt="calendar"
                style={{ width: '100%' }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{ mt: 1.5, mb: 1.5, ml: 1, fontWeight: 'bold' }}
            >
              동결 배아 현황
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ ml: 1, mb: 2 }}
            >
              총 누적 동결 배아 수: {cumulativeCount} 개
            </Typography>
            <Box sx={{ mb: 4 }}>
              <LineChart
                width={500}
                height={300}
                data={recentData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="retrievedDate" />
                <YAxis />
                <Tooltip />
                <Legend formatter={(value) => (value === 'freezedEmbryos' ? '월별 동결 배아' : '누적 동결 배아')} />
                <Line type="monotone" dataKey="freezedEmbryos" stroke="#82ca9d" name="월별 동결 배아" />
                <Line type="monotone" dataKey="cumulativeFreezedEmbryos" stroke="#8884d8" name="누적 동결 배아" />
              </LineChart>
            </Box>
            <Box sx={{ mb: 4 }}>
              <img
                src={checklistImage}
                alt="checklist"
                style={{ width: '100%' }}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <InjectionSchedule userId={5} handleClose={handleClose} fetchInjectionData={fetchInjectionData} />
        </Box>
      </Modal>
      <Modal
        open={openEmbryoModal}
        onClose={handleCloseEmbryoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Embryo userId={5} handleClose={handleCloseEmbryoModal} fetchEmbryoData={fetchEmbryoData} />
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;