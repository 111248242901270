import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography } from '@mui/material';
import Divier from '@mui/material/Divider';

const Embryo = ({ userId, handleClose, fetchEmbryoData }) => {
    const [retrievedDate, setRetrievedDate] = useState('');
    const [retrievedOva, setRetrievedOva] = useState(0);
    const [matureOva, setMatureOva] = useState(0);
    const [zygotes, setZygotes] = useState(0);
    const [threeDaysEmbryos, setThreeDaysEmbryos] = useState(0);
    const [fourDaysEmbryos, setFourDaysEmbryos] = useState(0);
    const [fiveDaysEmbryos, setFiveDaysEmbryos] = useState(0);
    const [freezedEmbryos, setFreezedEmbryos] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            userId,
            retrievedOva: parseInt(retrievedOva, 10),
            matureOva: parseInt(matureOva, 10),
            zygotes: parseInt(zygotes, 10),
            threeDaysEmbryos: parseInt(threeDaysEmbryos, 10),
            fourDaysEmbryos: parseInt(fourDaysEmbryos, 10),
            fiveDaysEmbryos: parseInt(fiveDaysEmbryos, 10),
            freezedEmbryos: parseInt(freezedEmbryos, 10),
            retrievedDate,
        };

        console.log('Sending POST request with data:', data);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST_URL}/embryo/`, data);
            console.log('Response:', response.data);
            // 성공적으로 데이터를 전송한 후 추가적인 처리를 할 수 있습니다.
            await fetchEmbryoData();
            handleClose(); // 폼 제출 후 모달 닫기
        } catch (error) {
            console.error('Error:', error);
            // 에러 처리 로직을 추가할 수 있습니다.
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, maxWidth: '100%' }}>
            <Typography variant="h6" gutterBottom>
                난자/배아 정보 추가
            </Typography>
            <Divier />
            <TextField
                label="난자 채취일자"
                value={retrievedDate}
                onChange={(e) => setRetrievedDate(e.target.value)}
                required
                fullWidth
                margin="normal"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="채취 난자 수"
                value={retrievedOva}
                onChange={(e) => setRetrievedOva(parseInt(e.target.value, 10))}
                type="number"
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
            />
            <TextField
                label="성숙 난자"
                value={matureOva}
                onChange={(e) => setMatureOva(parseInt(e.target.value, 10))}
                type="number"
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
            />
            <TextField
                label="수정란"
                value={zygotes}
                onChange={(e) => setZygotes(parseInt(e.target.value, 10))}
                type="number"
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
            />
            <TextField
                label="3일배아"
                value={threeDaysEmbryos}
                onChange={(e) => setThreeDaysEmbryos(parseInt(e.target.value, 10))}
                type="number"
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
            />
            <TextField
                label="4일배아"
                value={fourDaysEmbryos}
                onChange={(e) => setFourDaysEmbryos(parseInt(e.target.value, 10))}
                type="number"
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
            />
            <TextField
                label="5일배아"
                value={fiveDaysEmbryos}
                onChange={(e) => setFiveDaysEmbryos(parseInt(e.target.value, 10))}
                type="number"
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
            />
            <TextField
                label="동결배아"
                value={freezedEmbryos}
                onChange={(e) => setFreezedEmbryos(parseInt(e.target.value, 10))}
                type="number"
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
            />
            <Divier />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={handleClose} color="primary" sx={{ mr: 2 }}>
                    취소
                </Button>
                <Button type="submit" color="primary">
                    저장
                </Button>
            </Box>
        </Box>
    );
};

export default Embryo;